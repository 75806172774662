import { ChangeEvent } from "react";
import { Box, MenuItem } from "@mui/material";
import { useContextProdutor } from "src/Contexts/produtores/ProdutoresContext";
import { IValuesFilterContaCorrente } from "src/Contexts/produtores/ProdutoresTypes";
import { valuesDefaultFilterContaCorrente } from "src/Contexts/produtores/valuesDefaultInputsProdutor/valuesDefaultInputsProdutor";
import { UseAutoCompletedAll } from "src/Contexts/Utils/UseAutoCompletedAll";
import { AppTextField } from "src/Pages/components";
import { ModalFilterCore } from "src/Pages/components/ModalFilterCore/Index";

import { AutoCompleteProdutor } from "../../../../../components/AutoCompleted/AutoCompleteProdutor";
import { useHttpContaCorrente } from "../TableContaCorrente/Hooks/useHttpContaCorrente";
import { ComponentPrivate } from "src/Pages/components/ComponentPrivate/ComponentPrivate";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";

export const FilterSidebarContaCorrente = () => {
  const { setValueAutoProdutor, valueAutoProdutor } = UseAutoCompletedAll();
  const { handleGet } = useHttpContaCorrente();
  const { userPerfil } = useAuthContext();

  const isProducer = userPerfil?.type_user === "PRODUCER";

  const { nameSearch, currentPage, itensPerPage } = useConfigPageContext();

  const { valuesFilterContaCorrente, setValuesFilterContaCorrente } =
    useContextProdutor();

  const handleChange =
    (prop: keyof IValuesFilterContaCorrente) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      setValuesFilterContaCorrente({
        ...valuesFilterContaCorrente,
        [prop]: event.target.value,
      });
    };

  const ClearFilter = async () => {
    setValuesFilterContaCorrente(valuesDefaultFilterContaCorrente);
    setValueAutoProdutor({ id: "", label: "" });

    await handleGet({
      codeProducer: isProducer
        ? userPerfil?.type_user === "PRODUCER"
          ? String(userPerfil.id)
          : String(valueAutoProdutor.id)
        : undefined,
    });
  };

  const FilterSearch = async () => {
    await handleGet({
      itensPerPage,
      currentPage,
      nameSearch,
      codeProducer:
        userPerfil?.type_user === "PRODUCER"
          ? String(userPerfil.id)
          : String(valueAutoProdutor.id),

      startData: valuesFilterContaCorrente.startData,
      endDate: valuesFilterContaCorrente.endDate,
      isReceipt: valuesFilterContaCorrente.isReceipt,
    });
  };

  return (
    <ModalFilterCore FilterClear={ClearFilter} FilterSearch={FilterSearch}>
      <Box
        sx={{
          "& > .MuiAutocomplete-root, & > .MuiTextField-root": {
            flexWrap: "wrap",
            display: "flex",
            marginBottom: "1rem",
            marginTop: "10px",
          },
        }}
      >
        <ComponentPrivate isView={userPerfil?.type_user !== "PRODUCER"}>
          <AutoCompleteProdutor
            fullWidth
            label="Produtor"
            tamanho="100%"
            variant="outlined"
          />
        </ComponentPrivate>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
            alignItems: "center",
          }}
        >
          <AppTextField
            label="Data"
            sxStyle={{ opacity: "", pointerEvents: "auto", width: "45%" }}
            type="date"
            value={valuesFilterContaCorrente.startData}
            variant="outlined"
            onChange={handleChange("startData")}
          />
          até
          <AppTextField
            label="Data"
            sxStyle={{ opacity: "", pointerEvents: "auto", width: "45%" }}
            type="date"
            value={valuesFilterContaCorrente.endDate}
            variant="outlined"
            onChange={handleChange("endDate")}
          />
        </Box>
      </Box>

      <>
        <AppTextField
          select
          id="Tem_recibo"
          label="Tem recibo ?"
          sxStyle={{ width: "100%", opacity: "", pointerEvents: "auto" }}
          value={valuesFilterContaCorrente.isReceipt}
          variant="outlined"
          onChange={handleChange("isReceipt")}
        >
          <MenuItem value="ALL">TODOS</MenuItem>
          <MenuItem value="TRUE">SIM</MenuItem>
          <MenuItem value="FALSE">NÃO</MenuItem>
        </AppTextField>
      </>
    </ModalFilterCore>
  );
};
