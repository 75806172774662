import React, { useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useResetInputs } from "src/Contexts";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { ModalCore } from "src/Pages/components";
import { ButtonCore } from "src/Pages/components/ButtonCore/ButtonCore";
import { valuesPermissions } from "src/shared/Utils/ValuesPermissions";
import { FormContato } from "./FormContato";
import { FormContrato } from "./FormContrato";
import { FormDados } from "./FormDados";
import { FormTitular } from "./FormTitular";
import { UseFeatFormContratos } from "./Hooks/UseFeaturesFormulario";

interface IModalContrato {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalContratos = ({ openModal, setOpenModal }: IModalContrato) => {
  const { userPerfil } = useAuthContext();
  const { resetInputs } = useResetInputs();

  const { handleSubmit, loading: loadingSubmit } = UseFeatFormContratos({
    setOpenModal,
  });

  useEffect(() => {
    if (!openModal) resetInputs();
  }, [openModal]);

  return (
    <>
      <ButtonCore
        disabled={
          userPerfil?.type_user === "USER" &&
          !userPerfil?.permissions.permissions.includes(
            valuesPermissions.CONTRATO.CREATE.id
          )
        }
        startIcon={<AddIcon />}
        title="Criar Contrato"
        onClick={() => setOpenModal(true)}
      />

      <ModalCore
        loadingSubmit={loadingSubmit}
        open={openModal}
        setOpen={setOpenModal}
        sxStyle={{ width: "1250px" }}
        title="Contrato"
        titleSubmit="Salvar"
        onChange={handleSubmit}
      >
        <FormContrato />
        <FormTitular />
        <FormContato />
        <FormDados />
      </ModalCore>
    </>
  );
};
