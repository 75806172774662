import { useState } from "react";
import { useParams } from "react-router-dom";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { Tab, Tabs } from "@mui/material";

import { Contract } from "./Pages/Contract/Contract";
import { TableBordero } from "../../1 - Bordero/table/TableBordero";

export const ContractInternal = () => {
  const { id } = useParams();

  const [value, setValue] = useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  if (!id) return null;

  return (
    <TabContext value={value}>
      <Tabs value={value} onChange={handleChange}>
        <Tab label="Contrato" value="1" />
        <Tab label="Parcelas" value="2" />
      </Tabs>

      <TabPanel sx={{ padding: 0 }} value="1">
        <Contract idContract={id} />
      </TabPanel>

      <TabPanel sx={{ padding: 0 }} value="2">
        <TableBordero idContract={id} />
      </TabPanel>
    </TabContext>
  );
};
