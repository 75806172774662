import { useEffect, useState } from "react";
import { formatRemove } from "@format-string/core";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

interface IUseGetCpfAndCNPJ {
  isValid: boolean;
  data: {
    id: string;
    cod_proposta: string;
    tipo_contrato: string;

    titular_pf: string;
    rg_pf: string;
    mae_pf: string;
    estado_civil_pf:
      | "solteiro"
      | "casado"
      | "separado"
      | "divorciado"
      | "viuvo"
      | "";
    data_nacimento_pf: string;
    genero_pf: "masculino" | "feminino" | "outros" | "";
    responsavel_pf: string;
    cpf_responsavel_pf: string;
    data_nascimento_responsavel_pf: string;

    razao_social_pj: string;
    contato_responsavel_pj: string;
    cargo_pj: string;

    email: string;
    telefone_1: string;
    telefone_2: string;
    cep: string;
    endereco: string;
    endereco_numero: string;
    complemento: string;
    bairro: string;
    cidade: string;
    uf: string;
  };
}

export const UseGetCpfAndCNPJ = () => {
  const { id } = useConfigPageContext();
  const { handleGetAlert } = useLayoutMainContext();
  const {
    valuesInputsContrato: { tipo_contrato, cpf_cnpj },
  } = useContextContrato();

  const [dataTitular, setDataTitular] = useState<IUseGetCpfAndCNPJ>();
  const [open, setOpen] = useState(false);

  const valueFormat: string = formatRemove(cpf_cnpj);

  const isContractPJ = tipo_contrato === "PJ" && valueFormat.length === 14;
  const isContractPF = tipo_contrato === "PF" && valueFormat.length === 11;

  useEffect(() => {
    if (!id && (isContractPJ || isContractPF)) {
      api
        .get<IUseGetCpfAndCNPJ>(`/contratos/bycpfcnpj/${valueFormat}`)
        .then((result) => {
          const { isValid, data } = result.data;

          if (isValid) {
            setOpen(true);
            setDataTitular({ isValid, data });
          }
        })
        .catch((error) =>
          handleGetAlert({ message: error.response.data.message })
        );
    }
  }, [cpf_cnpj]);

  return { open, setOpen, dataTitular };
};
