import { ChangeEvent } from "react";
import { formatString } from "@format-string/core";
import { Box, MenuItem } from "@mui/material";
import { useContextProdutor } from "src/Contexts/produtores/ProdutoresContext";
import { IValuesFilterProducer } from "src/Contexts/produtores/ProdutoresTypes";
import { valuesDefaultFilterProducer } from "src/Contexts/produtores/valuesDefaultInputsProdutor/valuesDefaultInputsProdutor";
import { UseAutoCompletedAll } from "src/Contexts/Utils/UseAutoCompletedAll";
import { AppTextField } from "src/Pages/components";
import { DividerCore } from "src/Pages/components/DividerCore/DividerCore";
import { ModalFilterCore } from "src/Pages/components/ModalFilterCore/Index";

import { useHttpTableProdutor } from "../TableProducer/hooks/useHttpTableProdutor";

import { AutoCompleteFilias } from "./Components/AutoCompleteProdutor";

export const FilterSidebarProdutor = () => {
  const { setValueAutoProdutor } = UseAutoCompletedAll();
  const { handleGet } = useHttpTableProdutor();

  const { valuesFilterProducer, setValuesFilterProducer } =
    useContextProdutor();

  const handleChange =
    (prop: keyof IValuesFilterProducer, option?: "CPF_CNPJ") =>
    (event: ChangeEvent<HTMLInputElement>) => {
      if (option === "CPF_CNPJ") {
        setValuesFilterProducer({
          ...valuesFilterProducer,
          [prop]: formatString({
            type: "cpfOurCnpj",
            value: event.target.value,
          }),
        });
      } else {
        setValuesFilterProducer({
          ...valuesFilterProducer,
          [prop]: event.target.value,
        });
      }
    };

  const ClearFilter = async () => {
    setValuesFilterProducer(valuesDefaultFilterProducer);
    setValueAutoProdutor({ id: "", label: "" });

    await handleGet({
      isActiveProducer: valuesFilterProducer.isActiveProducer,
    });
  };

  const FilterSearch = async () => {
    await handleGet({
      codProducer: valuesFilterProducer.codProducer,
      nomeProducer: valuesFilterProducer.nomeProducer,
      cpfProducer: valuesFilterProducer.cpfProducer,

      isActiveProducer: valuesFilterProducer.isActiveProducer,
      functionProducer: valuesFilterProducer.functionProducer,
    });
  };

  return (
    <ModalFilterCore FilterClear={ClearFilter} FilterSearch={FilterSearch}>
      <Box
        sx={{
          "& > .MuiAutocomplete-root, & > .MuiTextField-root": {
            flexWrap: "wrap",
            display: "flex",
            marginBottom: "1rem",
            marginTop: "10px",
          },
        }}
      >
        <AppTextField
          label="Cod produtor"
          sxStyle={{ opacity: "", pointerEvents: "auto", width: "100%" }}
          value={valuesFilterProducer.codProducer}
          variant="outlined"
          onChange={handleChange("codProducer")}
        />
        <AppTextField
          label="Nome produtor"
          sxStyle={{ opacity: "", pointerEvents: "auto", width: "100%" }}
          value={valuesFilterProducer.nomeProducer}
          variant="outlined"
          onChange={handleChange("nomeProducer")}
        />
        <AppTextField
          label="CPF/CNPJ produtor"
          sxStyle={{ opacity: "", pointerEvents: "auto", width: "100%" }}
          value={valuesFilterProducer.cpfProducer}
          variant="outlined"
          onChange={handleChange("cpfProducer", "CPF_CNPJ")}
        />
      </Box>

      <DividerCore title="Por equipe" />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          "& > .MuiAutocomplete-root, & > .MuiTextField-root": {
            marginBottom: "1rem",
            marginTop: "10px",
          },
        }}
      >
        <AppTextField
          disabled
          label="Cod supervisor"
          sxStyle={{ opacity: "", pointerEvents: "auto", width: "45%" }}
          variant="outlined"
        />
        OU
        <AppTextField
          disabled
          label="Cod Gerente"
          sxStyle={{ opacity: "", pointerEvents: "auto", width: "45%" }}
          variant="outlined"
        />
      </Box>

      <DividerCore title="Opções" />
      <Box sx={{ marginBottom: "1rem", marginTop: "10px" }}>
        <AutoCompleteFilias
          disabled
          fullWidth
          label="Filial"
          tamanho="100%"
          variant="outlined"
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          "& > .MuiAutocomplete-root, & > .MuiTextField-root": {
            marginBottom: "1rem",
            marginTop: "10px",
          },
        }}
      >
        <AppTextField
          select
          id="Tem_recibo"
          label="Função ?"
          sxStyle={{ width: "45%", opacity: "", pointerEvents: "auto" }}
          value={valuesFilterProducer.functionProducer}
          variant="outlined"
          onChange={handleChange("functionProducer")}
        >
          <MenuItem value="ALL">Todos</MenuItem>
          <MenuItem value="CORRETOR">Corretor</MenuItem>
          <MenuItem value="SUPERVISOR">Supervisor</MenuItem>
          <MenuItem value="GERENTE">Gerente</MenuItem>
        </AppTextField>
        <AppTextField
          disabled
          select
          id="Tem_recibo"
          label="Tipo ?"
          sxStyle={{ width: "45%", opacity: "", pointerEvents: "auto" }}
          variant="outlined"
        >
          <MenuItem value="TODOS">Todos</MenuItem>
          <MenuItem value="SALAO">Salão</MenuItem>
          <MenuItem value="REPASSE">Repasse</MenuItem>
          <MenuItem value="FREE_LANCE">Free lance</MenuItem>
          <MenuItem value="CONECTOR">Conector</MenuItem>
          <MenuItem value="ADMINISTRATIVO">Administrativo</MenuItem>
          <MenuItem value="INTERNO">Interno</MenuItem>
          <MenuItem value="HOME">Home</MenuItem>
          <MenuItem value="START">Start</MenuItem>
          <MenuItem value="MOVE">Move</MenuItem>
          <MenuItem value="MASTER">Master</MenuItem>
        </AppTextField>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          "& > .MuiAutocomplete-root, & > .MuiTextField-root": {
            marginTop: "10px",
          },
        }}
      >
        <AppTextField
          select
          id="Ativo"
          label="Ativo ?"
          sxStyle={{ width: "45%", opacity: "", pointerEvents: "auto" }}
          value={valuesFilterProducer.isActiveProducer}
          variant="outlined"
          onChange={handleChange("isActiveProducer")}
        >
          <MenuItem value="ALL">Todos</MenuItem>
          <MenuItem value="TRUE">Sim</MenuItem>
          <MenuItem value="FALSE">Não</MenuItem>
        </AppTextField>

        <AppTextField
          disabled
          select
          id="Tem_recibo"
          label="Ordem ?"
          sxStyle={{ width: "45%", opacity: "", pointerEvents: "auto" }}
          variant="outlined"
        >
          <MenuItem value="TRUE">Por criação</MenuItem>
          <MenuItem value="ALL">Por código</MenuItem>
          <MenuItem value="TRUE">Por nome</MenuItem>
        </AppTextField>
      </Box>
    </ModalFilterCore>
  );
};
