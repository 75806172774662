import { Children, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { UseAutoCompletedAll } from "src/Contexts/Utils/UseAutoCompletedAll";
import AddHomeWorkIcon from "@mui/icons-material/AddHomeWork";
import {
  ModalDeleteCore,
  TableRowCore,
  TableCore,
  TdCore,
} from "src/Pages/components";
import { UseVerifyIsPermissionsUser } from "src/Pages/components/hooks/UseVerifyIsPermissionsUser";
import {
  EditIcon,
  FolderOffIcon,
  FolderCopyIcon,
  InfoOutlinedIcon,
  DeleteForeverIcon,
  CurrencyExchangeIcon,
} from "src/Pages/components/Icons/Icons";
import { UseDelete } from "src/Pages/components/ModalDeleteCore/Hooks/UseDelete";
import { ActionPopoverTable } from "src/Pages/components/table/ActionPopover/ActionPopover";
import { handleFormatDateDayWeek } from "src/shared/Utils/FormatDate/FormatDateToDayWeek";
import { FormatDateBR } from "src/shared/Utils/FormatDateBR";
import { FormatMoney } from "src/shared/Utils/FormatMoney";
import { handleFormatDateIfIsNull } from "src/shared/Utils/handleFormatDateIfIsNull";
import { cpfOurCnpj } from "src/shared/Utils/MaskCpfCnpj";
import { valuesPermissions } from "src/shared/Utils/ValuesPermissions";

import { FilterSidebar } from "../FilterSidebar/FilterSidebar";
import { ModalUpdateStatusContrato } from "../FormCancelContrato/FormCancelContrato";

import { UseGetByIdContratos } from "./Hooks/UseGetByIdContratos";
import { useHttpTableContratos } from "./Hooks/useHttpTableContratos";
import { THeadContratos } from "./THead";
import { ModalGetDataTitular } from "../ModalGetDataTitular";
import { ModalContratos } from "../Form";

export const TableContratos = () => {
  const { handleVerifyPermissionItem } = UseVerifyIsPermissionsUser();
  const {
    valueAutoCompOperadoraFilter,
    valueAutoCompProdutorActive,
    valueAutoCompAdministradora,
  } = UseAutoCompletedAll();
  const { handleGet } = useHttpTableContratos();

  const navigate = useNavigate();

  const { listContratos, valuesFilterContrato } = useContextContrato();
  const { handleGetById } = UseGetByIdContratos();

  const [openModalCancel, setOpenModalCancel] = useState(false);
  const [stateContrato, setStateContrato] = useState("");

  const [openModalContrato, setOpenModalContrato] = useState(false);

  const {
    setActive_incluir,
    setNameSearch,
    itensPerPage,
    currentPage,
    nameSearch,
    attTable,
    setId,
  } = useConfigPageContext();

  const handleGetIdToEdit = (id: string) => {
    setId(id);
    setOpenModalContrato(true);
    handleGetById(id);
  };

  useEffect(() => {
    handleGet({
      currentPage,
      itensPerPage,
      nameSearch,

      codProposta: valuesFilterContrato.cod_proposta,
      codeOperadora: valueAutoCompOperadoraFilter.id,
      codeCorretor: valueAutoCompProdutorActive.id,
      codeAdministradora: valueAutoCompAdministradora.id,

      endDateSignature: valuesFilterContrato.endDateSignature,
      startDateSignature: valuesFilterContrato.startDateSignature,

      startEffectiveDate: valuesFilterContrato.startEffectiveDate,
      startDateEmission: valuesFilterContrato.startDateEmission,

      endDateEmission: valuesFilterContrato.startDateEmission,
      endEffectiveDate: valuesFilterContrato.endEffectiveDate,
      client: {
        nameOrRazao: valuesFilterContrato.nameOrRazao,
        cpfCnpj: valuesFilterContrato.cpfCnpj,
      },
    });
  }, [attTable, itensPerPage, currentPage, nameSearch]);

  const {
    handleDelete,
    open: openModalDelete,
    loading: loadingDelete,
    setId: setIdModalDelete,
    setOpen: setOpenModalDelete,
  } = UseDelete({ url: "/contratos" });

  return (
    <TableCore
      Modals={
        <>
          <ModalDeleteCore
            loading={loadingDelete}
            modalOpen={openModalDelete}
            no="Não, Cancelar"
            titlePrimary="Você deseja excluir da lista ?"
            yes="Sim, Deletar !"
            onClickFalse={() => setOpenModalDelete(false)}
            onClickTrue={handleDelete}
          />

          <ModalUpdateStatusContrato
            openModalCancel={openModalCancel}
            setOpenModalCancel={setOpenModalCancel}
            stateContrato={stateContrato}
          />

          <ModalGetDataTitular />
        </>
      }
      cardAdd={{
        title: "Contrato",
        icon: <AddHomeWorkIcon />,
        buttonAdd: [
          <ModalContratos
            key={1}
            openModal={openModalContrato}
            setOpenModal={setOpenModalContrato}
          />,
        ],
      }}
      THead={<THeadContratos />}
      filter={<FilterSidebar />}
      qtdList={listContratos.length}
      setNameSearch={setNameSearch}
    >
      {Children.toArray(
        listContratos.map((item) => {
          return (
            <TableRowCore id={item.id}>
              <TdCore
                values={
                  <ActionPopoverTable
                    optionsList={[
                      {
                        icon: <CurrencyExchangeIcon fontSize="small" />,
                        title: "Ver Contrato",
                        background: "success",
                        onClick: () => {
                          navigate(`/Lancamentos/contrato/${item.id}`);
                        },
                      },
                      {
                        icon: <EditIcon fontSize="small" />,
                        title: "Editar",
                        background: "warning",
                        isDisabled: handleVerifyPermissionItem(
                          valuesPermissions.CONTRATO.EDITAR.id
                        ),
                        onClick: () => {
                          setId(item.id);
                          handleGetIdToEdit(item.id);
                          setActive_incluir(true);
                        },
                      },
                      {
                        title:
                          item.status_status === 1
                            ? "Cancelar Contrato"
                            : "Aprovar contrato",
                        isDisabled: handleVerifyPermissionItem(
                          valuesPermissions.CONTRATO.CANCELAR.id
                        ),
                        background: "warning",
                        onClick: () => {
                          setId(item.id);
                          setOpenModalCancel(true);
                          setStateContrato(
                            item.status_status === 1 ? "2" : "1"
                          );
                        },
                        icon:
                          item.status_status === 1 ? (
                            <FolderOffIcon fontSize="small" />
                          ) : (
                            <FolderCopyIcon fontSize="small" />
                          ),
                      },
                      {
                        title: "Excluir",
                        background: "error",
                        isDisabled: handleVerifyPermissionItem(
                          valuesPermissions.CONTRATO.DELETAR.id
                        ),
                        onClick: () => {
                          setIdModalDelete(item.id);
                          setOpenModalDelete(true);
                        },
                        icon: <DeleteForeverIcon fontSize="small" />,
                      },
                    ]}
                  />
                }
              />
              <TdCore
                color={item.status_status === 1 ? "#3cb916" : "#D0021B"}
                tooltip={
                  item.status_status === 1
                    ? ""
                    : `${item.descricao_cancelar} - ${FormatDateBR(
                        item.date_cancel
                      )}`
                }
                values={item.status_descricao.toUpperCase()}
              />
              <TdCore
                borderBottom={"1px solid  #0e6aff"}
                color="#0e6aff"
                textAlign="center"
                tooltip={handleFormatDateDayWeek(
                  handleFormatDateIfIsNull(item.data_assinatura)
                )}
                values={
                  item.data_assinatura ? FormatDateBR(item.data_assinatura) : ""
                }
              />
              <TdCore
                isLimit
                isLimitQTD={35}
                textAlign="left"
                values={`${item.code_operadoras} - ${item.name_operadoras}`}
              />

              <TdCore
                borderBottom={"1px solid  #0e6aff"}
                color="#0e6aff"
                padding="5px"
                values={item.cod_proposta}
              />
              <TdCore
                color="#3cb916"
                values={`R$ ${FormatMoney(item.mensalidade_value)}`}
              />
              <TdCore
                values={
                  item.tipo_contrato === "PF"
                    ? item.titular_pf
                    : item.razao_social_pj
                }
              />

              <TdCore
                textAlign="left"
                values={
                  item.tipo_contrato === "PF"
                    ? cpfOurCnpj({ text: item.cpf_cnpj, type: "cpf" })
                    : cpfOurCnpj({ text: item.cpf_cnpj, type: "cnpj" })
                }
              />

              <TdCore
                subTitle={cpfOurCnpj({
                  text: item.telefone_2,
                  type: "phone",
                })}
                values={cpfOurCnpj({
                  text: item.telefone_1,
                  type: "phone",
                })}
              />

              <TdCore values={`${item.id_produtor} - ${item.nome_produtor}`} />

              <TdCore
                isLimit
                isLimitQTD={35}
                textAlign="left"
                values={
                  item.code_administradoras &&
                  `${item.code_administradoras} - ${item.name_administradoras}`
                }
              />

              <TdCore
                isLimit
                isLimitQTD={35}
                textAlign="left"
                values={`${item.code_modalidades} - ${item.name_modalidades}`}
              />
              <TdCore
                textAlign="center"
                tooltip={`Usuário com id: '${
                  item.id_user_create
                }' criou na data: ${FormatDateBR(item.created_at)}
                  / Usuário com id '${
                    item.id_user_update
                  }' Editou na data: ${FormatDateBR(item.update_at)}`}
                values={<InfoOutlinedIcon />}
              />
            </TableRowCore>
          );
        })
      )}
    </TableCore>
  );
};
