import { formatString } from "@format-string/core";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { ModalCore } from "src/Pages/components";
import { TitleWithValueCore } from "src/Pages/components/TitleWithValueCore/TitleWithValueCore";
import { handleFormatCep } from "src/shared/Utils/FormatCEP";
import { FormatRG } from "src/shared/Utils/FormatRG/FormatRG";
import { handleFormatDateIfIsNull } from "src/shared/Utils/handleFormatDateIfIsNull";
import { cpfOurCnpj } from "src/shared/Utils/MaskCpfCnpj";

import { UseGetCpfAndCNPJ } from "../Form/Hooks/UseGetCpfAndCNPJ";

export const ModalGetDataTitular = () => {
  const { valuesInputsContrato, setValuesInputsContrato } =
    useContextContrato();

  const { open, setOpen, dataTitular } = UseGetCpfAndCNPJ();

  const handleUseData = () => {
    setOpen(false);

    if (dataTitular) {
      setValuesInputsContrato((prevEvent) => ({
        ...prevEvent,
        titular_pf: dataTitular?.data.titular_pf,
        rg_pf: FormatRG(dataTitular.data.rg_pf),

        mae_pf: dataTitular.data.mae_pf,
        estado_civil_pf: dataTitular.data.estado_civil_pf,
        data_nacimento_pf: handleFormatDateIfIsNull(
          dataTitular.data.data_nacimento_pf
        ),
        genero_pf: dataTitular.data.genero_pf,
        responsavel_pf: dataTitular.data.responsavel_pf,

        cpf_responsavel_pf: cpfOurCnpj({
          text: dataTitular.data.cpf_responsavel_pf,
          type: "cpf",
        }),

        data_nascimento_responsavel_pf:
          dataTitular.data.data_nascimento_responsavel_pf,

        razao_social_pj: dataTitular.data.razao_social_pj,
        contato_responsavel_pj: dataTitular.data.contato_responsavel_pj,
        cargo_pj: dataTitular.data.cargo_pj,

        email: dataTitular.data.email,
        telefone_1: formatString({
          type: "phone",
          value: dataTitular.data.telefone_1,
        }),
        telefone_2: formatString({
          type: "phone",
          value: dataTitular?.data.telefone_2,
        }),

        cep: handleFormatCep(dataTitular?.data?.cep),
        endereco: dataTitular?.data.endereco,
        endereco_numero: dataTitular?.data.endereco_numero ?? "",
        complemento: dataTitular?.data.complemento ?? "",
        bairro: dataTitular?.data.bairro,
        cidade: dataTitular?.data.cidade,
        uf: dataTitular?.data.uf,
      }));
    }
  };

  const isType = valuesInputsContrato.tipo_contrato === "PF";

  if (!dataTitular) return null;

  return (
    <ModalCore
      open={open}
      setOpen={setOpen}
      subTitle={valuesInputsContrato.cpf_cnpj}
      sxStyle={{ width: 500 }}
      title={`Encontramos dados com este ${
        valuesInputsContrato.tipo_contrato === "PF" ? "CPF" : "CNPJ"
      }`}
      titleSubmit="Usar dados"
      onChange={handleUseData}
    >
      <TitleWithValueCore
        title={isType ? "Titular:" : "Razão social:"}
        value={
          isType
            ? dataTitular?.data.titular_pf
            : dataTitular?.data.razao_social_pj
        }
      />
      ...
    </ModalCore>
  );
};
