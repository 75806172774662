import { formatRemove } from "@format-string/core";
import { useState } from "react";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { UseAutoCompletedAll } from "src/Contexts/Utils/UseAutoCompletedAll";
import { api } from "src/shared/setup/API/api";
import {
  apenasNumeros,
  handleFormatToNumeric,
} from "src/shared/Utils/FormatMoney";

interface IUseFormContratos {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UseFeatFormContratos = ({ setOpenModal }: IUseFormContratos) => {
  const { handleGetAlert } = useLayoutMainContext();

  const [loading, setLoading] = useState(false);

  const {
    valueAutoCompAdministradora,
    valueAutoCompletedFiliais,
    valueAutoCompModalidade,
    valueAutoCompOperadora,
  } = UseAutoCompletedAll();

  const { setAttTable, attTable, id } = useConfigPageContext();

  const {
    valuesInputsContrato,
    valueAutoCompProdutorAll,
    valueComissaoProdutorGrade,
    valueComissaoCorretoraGrade,
  } = useContextContrato();

  const AllDatas = {
    ...valuesInputsContrato,

    dependentes: valuesInputsContrato.dependentes.map((item) => {
      if (item.cpf || item.cpf_mae) {
        return {
          ...item,
          cpf: apenasNumeros(item.cpf),
          cpf_mae: apenasNumeros(item.cpf_mae),
        };
      }

      return item;
    }),

    code_operadora: Number(valueAutoCompOperadora.id),
    code_modalidade: Number(valueAutoCompModalidade.id),

    rg_pf: formatRemove(valuesInputsContrato.rg_pf),

    telefone_1: formatRemove(valuesInputsContrato.telefone_1),
    telefone_2: formatRemove(valuesInputsContrato.telefone_2),

    code_administradora: valueAutoCompAdministradora.id
      ? Number(valueAutoCompAdministradora.id)
      : null,

    codigo_corretor: valueAutoCompProdutorAll.id,
    id_filial: valueAutoCompletedFiliais.id,

    cep: apenasNumeros(valuesInputsContrato.cep as string),

    cpf_cnpj: apenasNumeros(valuesInputsContrato.cpf_cnpj as string),
    cpf_responsavel_pf: apenasNumeros(
      valuesInputsContrato.cpf_responsavel_pf as string
    ),
    mensalidade_value: handleFormatToNumeric(
      valuesInputsContrato.mensalidade_value
    ),
    is_producer_receveid_installment:
      valuesInputsContrato.is_producer_receveid_installment === "SIM",

    is_collect_fee_producer:
      valuesInputsContrato.is_collect_fee_producer === "SIM",
    taxa_value: handleFormatToNumeric(valuesInputsContrato.taxa_value),

    valueComissaoCorretoraGrade,
    valueComissaoProdutorGrade,
  };

  const handleCreateContratos = async () => {
    if (!AllDatas.codigo_corretor)
      return handleGetAlert({ message: `Escolha um produtor !` });
    if (!AllDatas.id_filial)
      return handleGetAlert({ message: `Escolha uma filial !` });
    if (!AllDatas.tipo_contrato)
      return handleGetAlert({
        message: `Escolha se contrato é Fisica | Jurídica !`,
      });
    if (!AllDatas.cod_proposta)
      return handleGetAlert({
        message: `Digite uma proposta !`,
      });
    if (!AllDatas.cpf_cnpj)
      return handleGetAlert({
        message: `Digite um CPF | CNPJ !`,
      });
    if (!AllDatas.mensalidade_value)
      return handleGetAlert({
        message: `Mensalidade é obrigatório !`,
      });

    if (!Number(AllDatas.mensalidade_value))
      return handleGetAlert({
        message: `Mensalidade é obrigatório !`,
      });
    if (!AllDatas.vidas)
      return handleGetAlert({
        message: `Número de vidas é obrigatório !`,
      });

    setLoading(true);

    return api
      .post("/contratos/create", AllDatas)
      .then((res) => {
        setAttTable(!attTable);
        handleGetAlert({ message: res.data.message });

        setOpenModal(false);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  const handleEditContratos = () => {
    if (!AllDatas.codigo_corretor)
      return handleGetAlert({ message: `Escolha um produtor !` });
    if (!AllDatas.id_filial)
      return handleGetAlert({ message: `Escolha uma filial !` });
    if (!AllDatas.tipo_contrato)
      return handleGetAlert({
        message: `Escolha se contrato é Física | Jurídica !`,
      });
    if (!AllDatas.cod_proposta)
      return handleGetAlert({
        message: `Digite uma proposta !`,
      });
    if (!AllDatas.cpf_cnpj)
      return handleGetAlert({
        message: `Digite um CPF | CNPJ !`,
      });
    if (!AllDatas.mensalidade_value)
      return handleGetAlert({
        message: `Mensalidade é obrigatório !`,
      });

    if (!Number(AllDatas.mensalidade_value))
      return handleGetAlert({
        message: `Mensalidade é obrigatório !`,
      });
    if (!AllDatas.vidas)
      return handleGetAlert({
        message: `Número de vidas é obrigatório !`,
      });

    setLoading(true);

    return api
      .put(`/contratos/${id}`, AllDatas)
      .then((res) => {
        setAttTable(!attTable);
        handleGetAlert({ message: res.data.message });
        setOpenModal(false);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  const handleSubmit = () => {
    if (id) {
      handleEditContratos();
    } else {
      handleCreateContratos();
    }
  };

  return { handleSubmit, loading };
};
