import { ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  ListItemIcon,
  ListItemText,
  ListItem,
  alpha,
  List,
} from "@mui/material";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useResetInputs } from "src/Contexts/hooks/useResetInputs";

type Props = {
  title: string;
  to: string;
  icon?: ReactNode;
  isProducer?: boolean;
};

export const AsideMenuLeft = ({ title, to, icon, isProducer }: Props) => {
  const { currentPage, setCurrentPage } = useConfigPageContext();
  const { pathname } = useLocation();
  const { resetInputs } = useResetInputs();

  const isActive = pathname === to || false;

  const resetCurrentPage = () => {
    if (currentPage !== 1) setCurrentPage(1);
    resetInputs();
  };

  return (
    <>
      {!isProducer && (
        <Link style={{ textDecoration: "none" }} to={to}>
          <List disablePadding component="div" sx={{ padding: "1px 0" }}>
            <ListItem
              button
              sx={{
                background: isActive ? alpha("#3980f5", 0.2) : "",
                borderRight: isActive ? `4px solid #3980f5` : "",
                color: "#3b3b3b",
                marginRight: "-15px",
                padding: "4px 12px",
                "&:hover": {
                  backgroundColor: alpha("#3980f5", 0.2),
                },
                transition: ".1s",
              }}
            >
              {icon ? (
                <ListItemIcon
                  sx={{
                    color: "#3b3b3b",
                    marginRight: "-20px",
                    marginLeft: "4px",
                  }}
                >
                  {icon}
                </ListItemIcon>
              ) : (
                <ListItemIcon
                  sx={{
                    color: "#3b3b3b",
                    marginRight: "-15px",
                  }}
                />
              )}

              <ListItemText
                disableTypography
                primary={title}
                sx={{
                  color: "#3b3b3b",
                  fontSize: "15px",
                }}
                onClick={resetCurrentPage}
              />
            </ListItem>
          </List>
        </Link>
      )}
    </>
  );
};
