import { Children, useEffect, useState } from "react";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextProdutor } from "src/Contexts/produtores/ProdutoresContext";
import {
  ModalDeleteCore,
  TableRowCore,
  TableCore,
  TdCore,
} from "src/Pages/components";
import { UseVerifyIsPermissionsUser } from "src/Pages/components/hooks/UseVerifyIsPermissionsUser";
import {
  CloseIcon,
  ReceiptIcon,
  InfoOutlinedIcon,
  DeleteForeverIcon,
  DescriptionIcon,
} from "src/Pages/components/Icons/Icons";
import { UseDelete } from "src/Pages/components/ModalDeleteCore/Hooks/UseDelete";
import { ActionPopoverTable } from "src/Pages/components/table/ActionPopover/ActionPopover";
import { handleFormatDateDayWeek } from "src/shared/Utils/FormatDate/FormatDateToDayWeek";
import { FormatDateBR } from "src/shared/Utils/FormatDateBR";
import { FormatMoney } from "src/shared/Utils/FormatMoney";
import { handleFormatDateIfIsNull } from "src/shared/Utils/handleFormatDateIfIsNull";
import { valuesPermissions } from "src/shared/Utils/ValuesPermissions";

import { handleChipCoreCategories } from "../../../../../components/CategoryComponentMap";
import { FilterSidebarContaCorrente } from "../FilterSidebar/FilterSidebar";
import { ModalRecibosPDF } from "../RecibosPDF/Index";

import { useHttpContaCorrente } from "./Hooks/useHttpContaCorrente";
import { THeadContaCorrente } from "./THead";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { UseAutoCompletedAll } from "src/Contexts/Utils/UseAutoCompletedAll";

export const TableContaCorrente = () => {
  const { valueAutoProdutor } = UseAutoCompletedAll();
  const { listContaCorrente, valuesFilterContaCorrente } = useContextProdutor();
  const { handleVerifyPermissionItem } = UseVerifyIsPermissionsUser();
  const [openModalRecibo, setOpenModalRecibo] = useState(false);
  const { handleGet } = useHttpContaCorrente();
  const { userPerfil } = useAuthContext();

  const {
    id,
    setId,
    attTable,
    nameSearch,
    currentPage,
    itensPerPage,
    setNameSearch,
  } = useConfigPageContext();

  useEffect(() => {
    handleGet({
      itensPerPage,
      currentPage,
      nameSearch,
      codeProducer:
        userPerfil?.type_user === "PRODUCER"
          ? String(userPerfil.id)
          : String(valueAutoProdutor.id),

      startData: valuesFilterContaCorrente.startData,
      endDate: valuesFilterContaCorrente.endDate,
      isReceipt: valuesFilterContaCorrente.isReceipt,
    });
  }, [attTable, itensPerPage, currentPage, nameSearch]);

  const {
    handleDelete,
    open: openModalDelete,
    loading: loadingDelete,
    setId: setIdModalDelete,
    setOpen: setOpenModalDelete,
  } = UseDelete({ url: "/contaCorrente" });

  return (
    <TableCore
      isLastChildren
      Modals={
        <>
          <ModalDeleteCore
            loading={loadingDelete}
            modalOpen={openModalDelete}
            no="Não, Cancelar"
            titlePrimary="Você deseja excluir da lista ?"
            titleSecondary={`ATENÇÃO !
            Se remover essa listagem será zerado o pagamento de todas as parcelas que foram efetuadas nesse recibo !
            DICA: Visualize o recibo primeiro, e salve o recibo para saber qual foi removido o pagamento !
            `}
            yes="Sim, Deletar !"
            onClickFalse={() => setOpenModalDelete(false)}
            onClickTrue={() => handleDelete()}
          />

          <ModalRecibosPDF
            id={id}
            opemModal={openModalRecibo}
            setOpenModal={setOpenModalRecibo}
          />
        </>
      }
      THead={<THeadContaCorrente />}
      filter={<FilterSidebarContaCorrente />}
      qtdList={listContaCorrente.length}
      setNameSearch={setNameSearch}
    >
      {Children.toArray(
        listContaCorrente.map((item) => {
          return (
            <TableRowCore id={item.id}>
              <TdCore
                values={
                  <ActionPopoverTable
                    optionsList={[
                      {
                        icon: <ReceiptIcon fontSize="small" />,
                        title: "Ver recibo",
                        background: "info",
                        isDisabled: !item.is_recibo,
                        onClick: () => {
                          setId(item.id);
                          setOpenModalRecibo(true);
                        },
                      },
                      {
                        icon: <DeleteForeverIcon fontSize="small" />,
                        title: "Excluir",
                        background: "error",
                        isDisabled:
                          item.is_start ||
                          handleVerifyPermissionItem(
                            valuesPermissions.CONTA_CORRENTE.DELETAR.id
                          ),
                        onClick: () => {
                          setIdModalDelete(item.id);
                          setOpenModalDelete(true);
                        },
                      },
                    ]}
                  />
                }
              />
              <TdCore
                isLimit
                isLimitQTD={35}
                values={`${item.id_produtor} - ${item.nome_produtor}`}
              />
              <TdCore
                borderBottom={"1px solid  #0e6aff"}
                color="#0e6aff"
                textAlign="center"
                tooltip={handleFormatDateDayWeek(
                  handleFormatDateIfIsNull(item.data_lancamento)
                )}
                values={FormatDateBR(item.data_lancamento)}
              />

              <TdCore isLimit isLimitQTD={55} values={item.descricao} />

              <TdCore values={handleChipCoreCategories(item.categoria)} />

              <TdCore
                color={item.valor < 0 ? "red" : "#3cb916"}
                textAlign="right"
                values={
                  item.tipo_transicao === "credito" &&
                  `R$ ${FormatMoney(String(item.valor))}`
                }
              />

              <TdCore
                color={item.valor < 0 ? "red" : "#3cb916"}
                textAlign="right"
                values={
                  item.tipo_transicao === "debito" &&
                  `R$ -${FormatMoney(String(item.valor))}`
                }
              />
              <TdCore
                color={item.saldo < 0 ? "red" : "#3cb916"}
                textAlign="right"
                values={`R$ ${item.saldo < 0 ? "-" : ""}${FormatMoney(
                  String(item.saldo)
                )}`}
              />
              <TdCore
                sxStyle={{ cursor: "pointer" }}
                textAlign="center"
                tooltip={
                  item.is_recibo
                    ? "Clique para abrir recibo"
                    : "Não tem recibo para abrir"
                }
                values={
                  item.is_recibo ? (
                    <DescriptionIcon color="info" />
                  ) : (
                    <CloseIcon color="error" />
                  )
                }
                onClick={() => {
                  if (item.is_recibo) {
                    setId(item.id);
                    setOpenModalRecibo(true);
                  }
                }}
              />
              <TdCore
                textAlign="center"
                tooltip={`Usuário com id: '${
                  item.id_user_create
                }' criou na data: ${FormatDateBR(item.created_at)}`}
                values={<InfoOutlinedIcon />}
              />
            </TableRowCore>
          );
        })
      )}
    </TableCore>
  );
};
