import { useState } from "react";
import { formatRemove } from "@format-string/core";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { useContextUsuarios } from "src/Contexts/Usuarios/UsuariosContext";
import { UseAutoCompletedAll } from "src/Contexts/Utils/UseAutoCompletedAll";
import { api } from "src/shared/setup/API/api";

interface IUseFormUser {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenModalViewPassword: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UseFormUser = ({
  setOpenModal,
  setOpenModalViewPassword,
}: IUseFormUser) => {
  const { setAttTable, id } = useConfigPageContext();
  const { valuesInputsUsuarios } = useContextUsuarios();
  const { handleGetAlert } = useLayoutMainContext();
  const [loading, setLoading] = useState(false);

  const { valueAutoCompletedFiliais, valueAutoCompPerfilAcesso } =
    UseAutoCompletedAll();
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  const handleCreate = async () => {
    if (!valuesInputsUsuarios.email)
      return handleGetAlert({ message: `Digite um email de acesso` });

    const AllDatas = {
      ...valuesInputsUsuarios,
      id_permissao: valueAutoCompPerfilAcesso.id,
      id_filial: valueAutoCompletedFiliais.id,
      telefone1: formatRemove(valuesInputsUsuarios.telefone1),
    };

    setLoading(true);

    return api
      .post("/createUsuarios", AllDatas)
      .then((res) => {
        setAttTable((item) => !item);
        handleGetAlert({ message: res.data.message });
        setOpenModal(false);
        setOpenModalViewPassword(true);
        setPassword(res.data.password);
        setEmail(res.data.email);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  const handleEdit = () => {
    if (!valuesInputsUsuarios.email)
      return handleGetAlert({ message: `Digite um email de acesso` });

    const AllDatas = {
      ...valuesInputsUsuarios,
      id_permissao: valueAutoCompPerfilAcesso.id,
      id_filial: valueAutoCompletedFiliais.id,
      telefone1: formatRemove(valuesInputsUsuarios.telefone1),
    };

    setLoading(true);

    return api
      .put(`/usuarios/${id}`, AllDatas)
      .then((res) => {
        setAttTable((item) => !item);
        handleGetAlert({ message: res.data.message });
        setOpenModal(false);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  const handleSubmit = () => {
    if (id) {
      handleEdit();
    } else {
      handleCreate();
    }
  };

  return { handleSubmit, loading, password, email };
};
