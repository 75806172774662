import React, { useEffect, useState } from "react";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { ModalCore } from "src/Pages/components";
import { ButtonCore } from "src/Pages/components/ButtonCore/ButtonCore";
import { UseVerifyIsPermissionsUser } from "src/Pages/components/hooks/UseVerifyIsPermissionsUser";
import { AddCardIcon } from "src/Pages/components/Icons/Icons";
import { valuesPermissions } from "src/shared/Utils/ValuesPermissions";

import { FormGetUltimaParcela } from "./FormGetParcelaById/FormGetUltimaParcela";
import { UseGetParcelasByIdContratos } from "./FormGetParcelaById/UseGetParcelasByIdContratos";
import { FormNovaParcela } from "./FormNovaParcela/FormNovaParcela";
import { UseFormNovaParcela } from "./FormNovaParcela/Hooks/UseFormNovaParcela";

interface IModalNovaParcela {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  id: string;
}

export const ModalNovaParcela = ({
  id,
  openModal,
  setOpenModal,
}: IModalNovaParcela) => {
  const { handleVerifyPermissionItem } = UseVerifyIsPermissionsUser();
  const { setValuesInputNovaParcelaBordero, valuesInputNovaParcelaBordero } =
    useContextContrato();

  const [value, setValue] = useState("");

  const { handleGetNovaParcelaById } = UseGetParcelasByIdContratos({
    id: id || value,
    isIntern: !!id,
  });

  useEffect(() => {
    setValuesInputNovaParcelaBordero(undefined);
  }, [value, openModal]);

  useEffect(() => {
    if (!openModal) {
      setValue("");
      setValuesInputNovaParcelaBordero(undefined);
    }
    if (openModal && id) {
      handleGetNovaParcelaById();
    }
  }, [openModal]);

  const { handleNovaParcela, loading: loadingModal } = UseFormNovaParcela({
    cod_contrato: id || value,
    setOpenModal,
    isIntern: !!id,
  });

  return (
    <>
      <ButtonCore
        disabled={handleVerifyPermissionItem(
          valuesPermissions.BORDERO.CREATE.id
        )}
        startIcon={<AddCardIcon />}
        title="Nova parcela"
        onClick={() => setOpenModal(true)}
      />

      <ModalCore
        loadingSubmit={loadingModal}
        open={openModal}
        setOpen={setOpenModal}
        sxStyle={{ width: "600px" }}
        title="Criar nova parcela"
        titleSubmit={
          !valuesInputNovaParcelaBordero ? "Procurar" : "Criar parcela"
        }
        onChange={
          !valuesInputNovaParcelaBordero
            ? handleGetNovaParcelaById
            : handleNovaParcela
        }
      >
        {valuesInputNovaParcelaBordero ? (
          <FormNovaParcela />
        ) : (
          <FormGetUltimaParcela setValue={setValue} value={value} />
        )}
      </ModalCore>
    </>
  );
};
