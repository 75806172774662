import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useResetInputs } from "src/Contexts";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { ModalCore } from "src/Pages/components";
import { ButtonCore } from "src/Pages/components/ButtonCore/ButtonCore";
import { valuesPermissions } from "src/shared/Utils/ValuesPermissions";

import { FormUser } from "./FormUser";
import { UseFormUser } from "./hook/UseFormUser";
import { UseGetByIdUser } from "./hook/UseGetByIdUser";
import { ModalViewPassWord } from "../ModalViewPassWord";

interface IModalUser {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalUser = ({ openModal, setOpenModal }: IModalUser) => {
  const { id } = useConfigPageContext();
  const { userPerfil } = useAuthContext();
  const { resetInputs } = useResetInputs();
  const { handleGetById } = UseGetByIdUser();

  const [openModalViewPassword, setOpenModalViewPassword] = useState(false);

  const {
    email,
    password,
    handleSubmit,
    loading: loadingSubmit,
  } = UseFormUser({
    setOpenModal,
    setOpenModalViewPassword,
  });

  useEffect(() => {
    if (openModal && id) {
      handleGetById(id);
    }

    if (!openModal) {
      resetInputs();
    }
  }, [openModal]);

  return (
    <>
      <ModalViewPassWord
        openModal={openModalViewPassword}
        setOpenModal={setOpenModalViewPassword}
        password={password}
        email={email}
      />
      <ButtonCore
        disabled={
          userPerfil?.type_user === "USER" &&
          !userPerfil?.permissions.permissions.includes(
            valuesPermissions.USER.CREATE.id
          )
        }
        startIcon={<AddIcon />}
        title="Criar usuário"
        onClick={() => setOpenModal(true)}
      />

      <ModalCore
        loadingSubmit={loadingSubmit}
        open={openModal}
        setOpen={setOpenModal}
        sxStyle={{ width: "650px" }}
        title="Usuário"
        titleSubmit="Salvar"
        onChange={handleSubmit}
      >
        <FormUser />
      </ModalCore>
    </>
  );
};
