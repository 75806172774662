import { Grid } from "@mui/material";

// import { BarChartIncomingExpense } from "./components/BarChartIncomingExpense";
import { CardsCount } from "./components/CardCount";
import { TableConfirmationAdministratorsDashboard } from "./components/ConfirmedByBranch/Administrators/Table";
import { TableConfirmationOperatorDashboard } from "./components/ConfirmedByBranch/Operadoras/Table";
import { InstallmentsReceived } from "./components/InstallmentsReceived";
import { InstallmentsReceivedPrimary } from "./components/InstallmentsReceivedPrimary";
import { MonthByMonthProfitChart } from "./components/MonthByMonthProfitChart";
// import { PieGraphicIncomingExpense } from "./components/PieGraphicIncomingExpense";

export const PageDashboardBroker = () => {
  return (
    <>
      <CardsCount />

      <MonthByMonthProfitChart />

      {/* <Grid container spacing={2} sx={{ marginBottom: "24px" }}>
        <Grid item xs={12} md={8}>
          <BarChartIncomingExpense />
        </Grid>

        <Grid item xs={12} md={4}>
          <PieGraphicIncomingExpense />
        </Grid>
      </Grid> */}

      <Grid container mb="24px" spacing={2}>
        {/* <Grid item md={4} xs={12}>
          <TableConfirmationFiliaisDashboard />
        </Grid> */}
        <Grid item md={6} xs={12}>
          <TableConfirmationOperatorDashboard />
        </Grid>
        <Grid item md={6} xs={12}>
          <TableConfirmationAdministratorsDashboard />
        </Grid>
      </Grid>

      <InstallmentsReceived />

      <InstallmentsReceivedPrimary />
    </>
  );
};
