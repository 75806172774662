import { useEffect, useState } from "react";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { UseAutoCompletedAll } from "src/Contexts/Utils/UseAutoCompletedAll";
import { api } from "src/shared/setup/API/api";

export const UseGetComissoesGrade = () => {
  const { id } = useConfigPageContext();
  const { handleGetAlert } = useLayoutMainContext();

  const {
    setValueComissaoCorretoraGrade,
    setValueComissaoProdutorGrade,
    valueComissaoProdutorGrade,
    valueAutoCompProdutorAll,
    valuesInputsContrato,
  } = useContextContrato();

  const {
    valueAutoCompAdministradora,
    valueAutoCompModalidade,
    valueAutoCompOperadora,
  } = UseAutoCompletedAll();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      // limpa comissões (Corretora e Produtor)
      id &&
      valueComissaoProdutorGrade?.corretor.code &&
      (!valuesInputsContrato.assinatura_data ||
        !valueAutoCompModalidade.id ||
        !valueAutoCompOperadora.id)
    ) {
      setValueComissaoCorretoraGrade(undefined);
      setValueComissaoProdutorGrade(undefined);
    }

    if (
      // limpa comissões (Corretora e Produtor)
      (!valuesInputsContrato.assinatura_data ||
        !valueAutoCompModalidade.id ||
        !valueAutoCompOperadora.id) &&
      !id
    ) {
      setValueComissaoCorretoraGrade(undefined);
      setValueComissaoProdutorGrade(undefined);
    }
  }, [
    valuesInputsContrato.assinatura_data,
    valueAutoCompModalidade.id,
    valueAutoCompOperadora.id,
  ]);

  useEffect(() => {
    // limpa comissões caso troque o vendedor (Produtor)
    if (!valueAutoCompProdutorAll.id) {
      setValueComissaoProdutorGrade(undefined);
    }
  }, [valueAutoCompProdutorAll.id]);

  useEffect(() => {
    // pega comissão da corretora
    if (
      valuesInputsContrato.assinatura_data &&
      valueAutoCompModalidade.id &&
      valueAutoCompOperadora.id
    ) {
      const GetCommission = async () => {
        setLoading(true);

        return api
          .get(
            `/comissoes/corretoraWithDate?date_vigencia=${valuesInputsContrato.assinatura_data}&code_operadora=${valueAutoCompOperadora.id}&code_modalidade=${valueAutoCompModalidade.id}&code_administradora=${valueAutoCompAdministradora.id}`
          )
          .then(({ data }) => setValueComissaoCorretoraGrade(data))
          .catch((error) => {
            handleGetAlert({ message: error.response.data.message });
            setValueComissaoCorretoraGrade(undefined);
          })
          .finally(() => setLoading(false));
      };

      GetCommission();
    }
  }, [
    valuesInputsContrato.assinatura_data,
    valueAutoCompAdministradora.id,
    valueAutoCompModalidade.id,
    valueAutoCompOperadora.id,
  ]);

  useEffect(() => {
    if (
      // pega comissão da produtor
      valuesInputsContrato.assinatura_data &&
      valuesInputsContrato.assinatura_data &&
      valueAutoCompModalidade.id &&
      valueAutoCompOperadora.id &&
      valueAutoCompProdutorAll.id
    ) {
      const GetCommissions = async () => {
        setLoading(true);

        return api
          .get(
            `/comissoes/produtorWithDate?date_vigencia=${valuesInputsContrato.assinatura_data}&code_operadora=${valueAutoCompOperadora.id}&code_modalidade=${valueAutoCompModalidade.id}&code_administradora=${valueAutoCompAdministradora.id}&id_corretor=${valueAutoCompProdutorAll.id}`
          )
          .then(({ data }) => setValueComissaoProdutorGrade(data))
          .catch((error) => {
            handleGetAlert({ message: error.response.data.message });
            setValueComissaoProdutorGrade(undefined);
          })
          .finally(() => setLoading(false));
      };

      GetCommissions();
    }
  }, [
    valuesInputsContrato.assinatura_data,
    valueAutoCompAdministradora.id,
    valueAutoCompProdutorAll.id,
    valueAutoCompModalidade.id,
    valueAutoCompOperadora.id,
  ]);

  return { loading };
};
