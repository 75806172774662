import { FC, ReactNode } from "react";
import { Box, SxProps, Typography } from "@mui/material";
import { FormCore } from "src/Pages/components";
import { toAbsoluteUrl } from "src/shared/setup/toAbsoluteUrl";

interface IBasePageLogin {
  sxStyle?: SxProps;
  children: ReactNode;
}

export const BasePageLogin: FC<IBasePageLogin> = ({ children, sxStyle }) => {
  return (
    <FormCore
      sxStyle={{
        margin: "0 auto",
        alignItems: "center",
        padding: { xs: "1.2rem", sm: "0" },
        ...sxStyle,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <Box sx={{ textAlign: "left" }}>
          <img
            alt="logo da empresa"
            src={toAbsoluteUrl("/media/logos/Extenso-BrokerIs.svg")}
            style={{ width: "160px" }}
          />
        </Box>
        <Box>
          <Typography sx={{ fontSize: "12px", mb: "4px", color: "#535252" }}>
            Email: tech@brokeris.com.br
          </Typography>
          <Typography sx={{ fontSize: "12px", color: "#535252" }}>
            Telefone: (21) 98014-3451
          </Typography>
        </Box>
      </Box>

      {children}
    </FormCore>
  );
};
