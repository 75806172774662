import { Box, Divider, Typography } from "@mui/material";
import { ModalCore } from "src/Pages/components";

interface IModalUser {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  password: string;
  email?: string;
}

export const ModalViewPassWord = ({
  openModal,
  setOpenModal,
  password,
  email,
}: IModalUser) => {
  return (
    <ModalCore
      open={openModal}
      setOpen={setOpenModal}
      sxStyle={{ width: "450px" }}
      title="Dados de acesso do usuário"
    >
      <Box sx={{ display: "flex", alignItems: "center", mb: "8px" }}>
        <Typography sx={{ marginRight: "10px" }}>Email: </Typography>
        <Typography
          sx={{
            fontWeight: 500,
            border: "1px solid gray",
            borderRadius: "8px",
            padding: "8px",
          }}
        >
          {email}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ marginRight: "8px" }}>Senha:</Typography>

        <Typography
          sx={{
            fontWeight: 500,
            border: "1px solid gray",
            borderRadius: "8px",
            padding: "8px",
          }}
        >
          {password}
        </Typography>
      </Box>

      <Divider sx={{ margin: "12px 0" }} />

      <Typography sx={{ fontSize: "12px" }}>
        A senha também foi enviada para o email do usuário, caso seja um email
        incorreto, necessário salvar esses dados!
      </Typography>
    </ModalCore>
  );
};
