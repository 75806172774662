import { createRef, ChangeEvent } from "react";
import { formatString } from "@format-string/core";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextProdutor } from "src/Contexts/produtores/ProdutoresContext";
import { ICreateProdutor } from "src/Contexts/produtores/ProdutoresTypes";
import { ButtonsFeaturesFormularioCore, FormCore } from "src/Pages/components";
import { AppTextField } from "src/Pages/components/AppFormComponents/AppTextField";
import { AutoCompleteFiliaisGeneric } from "src/Pages/components/AutoCompleted/AutoCompleteFiliais";
import { DividerCore } from "src/Pages/components/DividerCore/DividerCore";
import { UseVerifyIsPermissionsUser } from "src/Pages/components/hooks/UseVerifyIsPermissionsUser";
import { handleFormatCep } from "src/shared/Utils/FormatCEP";
import { FormatRG } from "src/shared/Utils/FormatRG/FormatRG";
import { unidadesFederativas } from "src/shared/Utils/ListUFBR";
import { valuesPermissions } from "src/shared/Utils/ValuesPermissions";

import { AutoCompleteGerenteAll } from "../../../../../components/AutoCompleted/AutoCompleteGerente";
import { AutoCompleteGrupoImposto } from "../../../../../components/AutoCompleted/AutoCompleteGrupoImposto";
import { AutoCompleteSupervisorAll } from "../../../../../components/AutoCompleted/AutoCompleteSupervisor";

import { UseFeaturesFormProducer } from "./hook/UseFeaturesFormProducer";
import { UseGetCEPProducer } from "./hook/UseGetCEPProducer";

export const FormProducer = () => {
  const { handleVerifyPermissionItem } = UseVerifyIsPermissionsUser();
  const { idTable, active_incluir } = useConfigPageContext();
  const { handleSubmit } = UseFeaturesFormProducer();
  const { valuesInputsProdutor, setValuesInputsProdutor } =
    useContextProdutor();

  UseGetCEPProducer();

  const nome = createRef<HTMLInputElement>();
  const handleFocusedID = () => nome.current?.focus();

  const handleChange =
    (
      prop: keyof ICreateProdutor,
      option?: "rg" | "phone" | "CPF_CNPJ" | "CEP"
    ) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      if (option === "CEP") {
        setValuesInputsProdutor((prevEvent) => ({
          ...prevEvent,
          [prop]: handleFormatCep(event.target.value),
        }));
      } else if (option === "CPF_CNPJ") {
        if (valuesInputsProdutor.fisico_juridico === "fisica") {
          setValuesInputsProdutor((prevValue) => ({
            ...prevValue,
            [prop]: formatString({ value: event.target.value, type: "cpf" }),
          }));
        }
        if (valuesInputsProdutor.fisico_juridico === "juridica") {
          setValuesInputsProdutor((prevValue) => ({
            ...prevValue,
            [prop]: formatString({ value: event.target.value, type: "cnpj" }),
          }));
        }
      } else if (option === "phone") {
        setValuesInputsProdutor((prevValue) => ({
          ...prevValue,
          [prop]: formatString({ value: event.target.value, type: "phone" }),
        }));
      } else if (option === "rg") {
        setValuesInputsProdutor((prevValue) => ({
          ...prevValue,
          [prop]: FormatRG(event.target.value),
        }));
      } else {
        setValuesInputsProdutor((prevValue) => ({
          ...prevValue,
          [prop]: event.target.value,
        }));
      }
    };

  return (
    <FormCore>
      <ButtonsFeaturesFormularioCore
        disabledEditar={handleVerifyPermissionItem(
          valuesPermissions.PRODUCER.EDITAR.id
        )}
        disabledIncluir={handleVerifyPermissionItem(
          valuesPermissions.PRODUCER.CREATE.id
        )}
        handleFocusedID={handleFocusedID}
        handleSubmit={handleSubmit}
      />
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {idTable && (
          <AppTextField
            disabled
            label="Cod"
            sxStyle={{ width: "60px" }}
            value={valuesInputsProdutor.id}
            onChange={handleChange("id")}
          />
        )}
        <AppTextField
          inputRef={nome}
          label="Nome completo *"
          sxStyle={{ width: "250px" }}
          value={valuesInputsProdutor?.nome}
          onChange={handleChange("nome")}
        />
        <AppTextField
          select
          defaultValue="ASSINATURA"
          id="Fisica | Juridica *"
          label="Fisica | Juridica"
          sxStyle={{ width: "120px" }}
          value={valuesInputsProdutor?.fisico_juridico}
          onChange={handleChange("fisico_juridico")}
        >
          <MenuItem value="fisica">Fisica</MenuItem>
          <MenuItem value="juridica">Juridica</MenuItem>
        </AppTextField>
        <AppTextField
          label="CPF/CNPJ"
          sxStyle={{ width: "170px" }}
          value={valuesInputsProdutor?.cpf_cnpj}
          onChange={handleChange("cpf_cnpj", "CPF_CNPJ")}
        />
        <AppTextField
          label="RG"
          sxStyle={{ width: "120px" }}
          value={valuesInputsProdutor?.rg}
          onChange={handleChange("rg", "rg")}
        />

        <AutoCompleteFiliaisGeneric
          disabled={!active_incluir}
          label="Filial *"
          tamanho="180px"
        />

        <AppTextField
          select
          disabled={!!idTable}
          id="Funcão_Produtor"
          label="Funcão Produtor"
          sxStyle={{ width: "110px" }}
          value={valuesInputsProdutor?.funcao_produtor}
          onChange={handleChange("funcao_produtor")}
        >
          <MenuItem value="CORRETOR">Corretor</MenuItem>
          <MenuItem value="SUPERVISOR">Supervisor</MenuItem>
          <MenuItem value="GERENTE">Gerente</MenuItem>
        </AppTextField>
        <AppTextField
          select
          id="tipo_relatorio"
          label="Tipo Produtor"
          sxStyle={{ width: "110px" }}
          value={valuesInputsProdutor?.tipo_produtor}
          onChange={handleChange("tipo_produtor")}
        >
          <MenuItem value="SALAO">Salão</MenuItem>
          <MenuItem value="REPASSE">Repasse</MenuItem>
          <MenuItem value="FREE_LANCE">Free lance</MenuItem>

          <MenuItem value="CONECTOR">Conector</MenuItem>
          <MenuItem value="ADMINISTRATIVO">Administrativo</MenuItem>
          <MenuItem value="INTERNO">Interno</MenuItem>
          <MenuItem value="HOME">Home</MenuItem>
          <MenuItem value="START">Start</MenuItem>
          <MenuItem value="MOVE">Move</MenuItem>
          <MenuItem value="MASTER">Master</MenuItem>
        </AppTextField>

        <AutoCompleteGrupoImposto
          disabled={!active_incluir}
          label="Grupo de imposto *"
          tamanho="200px"
        />
        <AutoCompleteSupervisorAll
          disabled={!active_incluir}
          label="Supervisor"
          tamanho="250px"
        />
        <AutoCompleteGerenteAll
          disabled={!active_incluir}
          label="Gerente"
          tamanho="250px"
        />
      </Box>

      <DividerCore title="Dados" />

      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <AppTextField
          label="Email Contato"
          sxStyle={{ width: "300px" }}
          value={valuesInputsProdutor?.email_contato}
          onChange={handleChange("email_contato")}
        />
        <AppTextField
          label="Telefone 1"
          sxStyle={{ width: "140px" }}
          value={valuesInputsProdutor?.telefone1}
          onChange={handleChange("telefone1", "phone")}
        />
        <AppTextField
          label="Telefone 2"
          sxStyle={{ width: "140px" }}
          type="telefone"
          value={valuesInputsProdutor?.telefone2}
          onChange={handleChange("telefone2", "phone")}
        />

        <AppTextField
          label="Data nascimento"
          sxStyle={{ width: "130px" }}
          type="date"
          value={valuesInputsProdutor?.data_nasc}
          onChange={handleChange("data_nasc")}
        />
        <AppTextField
          label="CEP"
          sxStyle={{ width: "100px" }}
          value={valuesInputsProdutor?.cep}
          onChange={handleChange("cep", "CEP")}
        />
        <AppTextField
          label="Endereço"
          sxStyle={{ width: "200px" }}
          value={valuesInputsProdutor?.endereco}
          onChange={handleChange("endereco")}
        />

        <FormControl
          focused
          disabled={!active_incluir}
          sx={{ width: "80px", margin: "0 6px" }}
          variant="standard"
        >
          <InputLabel id="demo-simple-select-label">UF</InputLabel>
          <Select
            MenuProps={{
              PaperProps: {
                style: {
                  width: 100,
                  maxHeight: 250,
                },
              },
            }}
            id="demo-simple-select"
            label="UF"
            labelId="demo-simple-select-label"
            value={valuesInputsProdutor?.estado}
            onChange={handleChange("estado") as never}
          >
            <MenuItem value=" " />
            {unidadesFederativas.map((item) => {
              return (
                <MenuItem key={item.sigla} value={item.sigla}>
                  {item.nome}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <AppTextField
          label="Cidade"
          sxStyle={{ width: "150px" }}
          value={valuesInputsProdutor?.cidade}
          onChange={handleChange("cidade")}
        />
        <AppTextField
          label="Bairro"
          sxStyle={{ width: "150px" }}
          value={valuesInputsProdutor?.bairro}
          onChange={handleChange("bairro")}
        />

        <AppTextField
          label="Entrada"
          sxStyle={{ width: "120px" }}
          type="date"
          value={valuesInputsProdutor?.entrada}
          onChange={handleChange("entrada")}
        />
        <AppTextField
          label="Saída"
          sxStyle={{ width: "120px" }}
          type="date"
          value={valuesInputsProdutor?.saida}
          onChange={handleChange("saida")}
        />
        <AppTextField
          label="Observacoes"
          sxStyle={{ width: "180px" }}
          value={valuesInputsProdutor?.observacoes}
          onChange={handleChange("observacoes")}
        />
      </Box>

      <DividerCore title="Dados bancário" />

      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <AppTextField
          label="Pix"
          sxStyle={{ width: "150px" }}
          value={valuesInputsProdutor?.pix}
          onChange={handleChange("pix")}
        />
        <AppTextField
          label="Banco"
          sxStyle={{ width: "60px" }}
          value={valuesInputsProdutor?.banco}
          onChange={handleChange("banco")}
        />
        <AppTextField
          label="Agencia"
          sxStyle={{ width: "60px" }}
          value={valuesInputsProdutor?.agencia}
          onChange={handleChange("agencia")}
        />
        <AppTextField
          label="Conta"
          sxStyle={{ width: "110px" }}
          value={valuesInputsProdutor?.conta}
          onChange={handleChange("conta")}
        />
        <AppTextField
          label="Favorecido"
          sxStyle={{ width: "180px" }}
          value={valuesInputsProdutor?.favorecido}
          onChange={handleChange("favorecido")}
        />
      </Box>
    </FormCore>
  );
};
