import { ChangeEvent, useState } from "react";
import { formatString } from "@format-string/core";
import { Box, MenuItem } from "@mui/material";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { ICreateContrato } from "src/Contexts/Lancamentos/Contrato/ContratosTypes";
import { AppTextField, ButtonPersonalized } from "src/Pages/components";
import { DividerCore } from "src/Pages/components/DividerCore/DividerCore";
import { FormatRG } from "src/shared/Utils/FormatRG/FormatRG";
import { cpfOurCnpj } from "src/shared/Utils/MaskCpfCnpj";

import { ModalAddDependentes } from "./FormAddDependentes";

export const FormTitular = () => {
  const { valuesInputsContrato, setValuesInputsContrato } =
    useContextContrato();

  const [openModalAddDependentes, setOpenModalAddDependentes] = useState(false);
  const [view, setView] = useState(true);

  const handleChange =
    (prop: keyof ICreateContrato, option?: "cpf" | "rg" | "cpfCnpj") =>
    (event: ChangeEvent<HTMLInputElement>) => {
      if (option === "cpfCnpj") {
        if (
          valuesInputsContrato.tipo_contrato === "PF" &&
          event.target.value.length <= 14
        ) {
          setValuesInputsContrato({
            ...valuesInputsContrato,
            [prop]: cpfOurCnpj({ text: event.target.value, type: "cpf" }),
          });
        }
        if (
          valuesInputsContrato.tipo_contrato === "PJ" &&
          event.target.value.length <= 20
        ) {
          setValuesInputsContrato({
            ...valuesInputsContrato,
            [prop]: cpfOurCnpj({ text: event.target.value, type: "cnpj" }),
          });
        }
      } else if (option === "cpf") {
        setValuesInputsContrato({
          ...valuesInputsContrato,
          [prop]: formatString({ value: event.target.value, type: "cpf" }),
        });
      } else if (option === "rg") {
        setValuesInputsContrato({
          ...valuesInputsContrato,
          [prop]: FormatRG(event.target.value),
        });
      } else {
        setValuesInputsContrato({
          ...valuesInputsContrato,
          [prop]: event.target.value,
        });
      }
    };

  return (
    <>
      <DividerCore title="Titular" onClick={() => setView((item) => !item)} />

      <ModalAddDependentes
        openModalDependentes={openModalAddDependentes}
        setOpenModalDependentes={setOpenModalAddDependentes}
      />

      <Box sx={{ display: view ? "flex" : "none", flexWrap: "wrap" }}>
        {valuesInputsContrato.tipo_contrato === "PF" && (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <AppTextField
              label="CPF *"
              sxStyle={{ width: "200px", opacity: "", pointerEvents: "auto" }}
              value={valuesInputsContrato.cpf_cnpj}
              onChange={handleChange("cpf_cnpj", "cpfCnpj")}
            />
            <AppTextField
              label="Titular *"
              sxStyle={{ width: "280px", opacity: "", pointerEvents: "auto" }}
              value={valuesInputsContrato.titular_pf}
              onChange={handleChange("titular_pf")}
            />
            <AppTextField
              label="RG"
              sxStyle={{ width: "140px", opacity: "", pointerEvents: "auto" }}
              value={valuesInputsContrato.rg_pf}
              onChange={handleChange("rg_pf", "rg")}
            />

            <AppTextField
              select
              id="Estado Civil"
              label="Estado civil"
              sxStyle={{ width: "110px", opacity: "", pointerEvents: "auto" }}
              value={valuesInputsContrato?.estado_civil_pf}
              onChange={handleChange("estado_civil_pf")}
            >
              <MenuItem value=" " />
              <MenuItem value="solteiro">Solteiro</MenuItem>
              <MenuItem value="casado">Casado</MenuItem>
              <MenuItem value="separado">Separado</MenuItem>
              <MenuItem value="divorciado">Divorciado</MenuItem>
              <MenuItem value="viuvo">Viúvo</MenuItem>
            </AppTextField>

            <AppTextField
              label="Data nascimento"
              sxStyle={{ width: "120px", opacity: "", pointerEvents: "auto" }}
              type="date"
              value={valuesInputsContrato.data_nacimento_pf}
              onChange={handleChange("data_nacimento_pf")}
            />

            <AppTextField
              select
              id="Gênero"
              label="Gênero"
              sxStyle={{ width: "100px", opacity: "", pointerEvents: "auto" }}
              value={valuesInputsContrato?.genero_pf}
              onChange={handleChange("genero_pf")}
            >
              <MenuItem value="" />
              <MenuItem value="masculino">Masculino</MenuItem>
              <MenuItem value="feminino">Feminino</MenuItem>
              <MenuItem value="outros">Outros</MenuItem>
            </AppTextField>

            <AppTextField
              label="Mãe"
              sxStyle={{ width: "250px", opacity: "", pointerEvents: "auto" }}
              value={valuesInputsContrato.mae_pf}
              onChange={handleChange("mae_pf")}
            />

            <AppTextField
              label="Nome do responsável"
              sxStyle={{ width: "200px", opacity: "", pointerEvents: "auto" }}
              value={valuesInputsContrato.responsavel_pf}
              onChange={handleChange("responsavel_pf")}
            />
            <AppTextField
              label="CPF Responsável"
              sxStyle={{ width: "150px", opacity: "", pointerEvents: "auto" }}
              value={valuesInputsContrato.cpf_responsavel_pf}
              onChange={handleChange("cpf_responsavel_pf", "cpf")}
            />
            <AppTextField
              label="Data nasc. Resp"
              sxStyle={{ width: "120px", opacity: "", pointerEvents: "auto" }}
              type="date"
              value={valuesInputsContrato.data_nascimento_responsavel_pf}
              onChange={handleChange("data_nascimento_responsavel_pf")}
            />
          </Box>
        )}
        {valuesInputsContrato.tipo_contrato === "PJ" && (
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <AppTextField
              label="CNPJ *"
              sxStyle={{ width: "200px", opacity: "", pointerEvents: "auto" }}
              value={valuesInputsContrato.cpf_cnpj}
              onChange={handleChange("cpf_cnpj", "cpfCnpj")}
            />
            <AppTextField
              label="Razão social *"
              sxStyle={{ width: "280px", opacity: "", pointerEvents: "auto" }}
              value={valuesInputsContrato.razao_social_pj}
              onChange={handleChange("razao_social_pj")}
            />
            <AppTextField
              label="Contato/Responsavel"
              sxStyle={{ width: "220px", opacity: "", pointerEvents: "auto" }}
              value={valuesInputsContrato.contato_responsavel_pj}
              onChange={handleChange("contato_responsavel_pj")}
            />
            <AppTextField
              label="Cargo"
              sxStyle={{ width: "150px", opacity: "", pointerEvents: "auto" }}
              value={valuesInputsContrato.cargo_pj}
              onChange={handleChange("cargo_pj")}
            />
          </Box>
        )}
        <ButtonPersonalized
          title="Add Dependentes"
          onClick={() => setOpenModalAddDependentes(true)}
        />
      </Box>
    </>
  );
};
